
import firebase from 'lib/firebase';
import { useEffect } from 'react';
import { getIsAdmin } from 'lib/db/users';
import auth from 'lib/auth';
import { NextRouter } from 'next/router';

const redirectLoggedIn = async (user: DG.UserModel | null, router: NextRouter) => {
  if (user && user.signupComplete) {
    const isAdmin = await getIsAdmin(user.uid);
    
    if (isAdmin) {
      router.push('/admin');
    } else if (user.username) {
      router.push(`/wishlist/${user.username}/items`);
    }
  }
}

export interface WishlistRedirectOpts {
  authCallback?: (user: DG.UserModel | null, fbUser: firebase.User | null) => void;
}

// For /wishlist pages
export function useHomepageRedirect(router: NextRouter, opts: WishlistRedirectOpts = {}) {
  useEffect(() => {
    const unsubscribe = auth.onDbUserChanged((user, fbUser) => {
      if (!user || !user.signupComplete) {
        router.push('/');
      }
      if (opts.authCallback) { opts.authCallback(user, fbUser); }
    });
    return () => {
      unsubscribe();
    };
  }, []);
}

export default function useWishlistRedirect(router: NextRouter, opts: WishlistRedirectOpts = {}) {
  useEffect(() => {
    const unsubscribe = auth.onDbUserChanged((user, fbUser) => {
      redirectLoggedIn(user, router);
      if (opts.authCallback) { opts.authCallback(user, fbUser); }
    });
    return unsubscribe;
  }, []);
}
