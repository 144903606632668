import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';
import cx from 'classnames';
import styles from './DreamButton.module.scss';

type DreamButtonProps = React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { disabled?: boolean; contentClassName?: string };

const DreamButton: React.FC<DreamButtonProps> = ({ className, children, contentClassName, onClick, disabled, ...rest }) => {

  return (
    <div {...rest} className={cx(styles.button, className, disabled && styles.disabled)} onClick={disabled ? undefined : onClick}>
      <div className={cx(styles.content, contentClassName)}>{children}</div>
      <div className={styles.bgWrap}>
        <div className={styles.bgHover}></div>
        <div className={styles.bg}></div>
      </div>
    </div>
  );
};

export default DreamButton;